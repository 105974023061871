import { eventChannel } from 'redux-saga';
import { take, call, put, fork, select } from 'redux-saga/effects';
import { HIT, SKIP, GIVE_UP } from '../game/game.actions';
import { getSocket } from './online.saga';

// This is how channel is created
const createSocketChannel = socket => eventChannel((emit) => {
  const handler = (data) => {
    emit(data);
  };
  socket.on('game_sync', handler);
  return () => {
    socket.off('game_sync', handler);
  };
});

function* watchUserActionSaga() {
  while (true) {
    const action = yield take([HIT, SKIP, GIVE_UP]);
    const user = yield select(state => {
      console.log(state, state.board.users[action.payload.player].type);
      return state.board.users[action.payload.player].type;
    });

    const socket = getSocket();
    if ((user === 'user' || action.type === GIVE_UP)  && socket) {
      socket.emit('game_action', action);
    }
  }
};

export function* gameSaga(socket){
  const socketChannel = yield call(createSocketChannel, socket);
  yield fork(watchUserActionSaga);
  while (true) {
    const action = yield take(socketChannel);
    //todo add type checks
    console.error('Socket.action', JSON.stringify(action));
    yield put({ type: action.type, payload: action.payload });
  }
}

export const createGame = async () => {
  const socket = getSocket();
  return new Promise((resolve) => {
    socket.emit('game_create', null, (data) => {
      resolve(data);
    });
  });
}

export const joinGame = async (id, username) => {
  const socket = getSocket();
  return new Promise((resolve) => {
    socket.emit('game_join', { id, username });
    socket.on('game_start', (data) => {
      resolve(data);
    });
  });
}