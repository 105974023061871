import React, { useState, useCallback, useRef, useEffect } from 'react';
import { startGameAction } from '../../modules/game/game.actions';
import { startChannel, stopChannel } from '../../modules/online/online.actions';
import { createGame, createTournament } from '../../modules/online/online.saga';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

const textAreaStyle = {
    width: '200px',
    fontFamily: 'Gloria Hallelujah'
}

function SelectPage(props) {
    console.log(props);

    const { 
        pushLocalPlay, pushJoin, pushJoinTournament, startGameAction, startChannelAction, stopChannelAction, 
        serverStatus, channelStatus, username 
    } = props;
    const [isTournament, setIsTournament] = useState(false);
    const [target, setTarget] = useState('ai');
    const [levelAI, setLevelAI] = useState('low');
    // const [joinLink, setJoinLink] = useState('---');
  
    useEffect(function(){
        if(target === 'online' || isTournament){
            channelStatus === 'off' && startChannelAction();
        } else {
            channelStatus === 'on' && stopChannelAction();
        }
    },[target, isTournament]);


    const targetRef = useCallback(node => {
        node && node.addEventListener('selected', (e) => setTarget(e.detail.selected));
    }, []);

    const aiRef = useCallback(node => {
        node && node.addEventListener('selected', (e) => setLevelAI(e.detail.selected));
    }, []);

    const isTournamentRef = useCallback(node => {
        node && node.addEventListener('selected', (e) => setIsTournament((/true/i).test(e.detail.selected)));
    }, []);


    const play = () => {
        startGameAction({
            red: {
                type:'user',
                name: username
            },
            blue: {
                type: target === 'ai' ? `bot.${levelAI}` : 'user',
                name: target === 'ai' ? 'BOT' : 'USER#2'
            } 
        });
        pushLocalPlay();
    }

    const create = async () => {
        const { id } = await createGame();
        pushJoin(id);
    }

    const createTournamentAction = async () => {
        const { id } = await createTournament(username);
        pushJoinTournament(id);
    }

    const inDevelopment = false;
    const isOnlineTarget = target === 'online';
    const isOnline = serverStatus === 'on';

    return (
        <div className="Home">
            <h3>#select game </h3>

            <div>
                <wired-listbox horizontal selected={isTournament} ref={isTournamentRef}>
                    <wired-item value={false}>single match</wired-item>
                    <wired-item value={true}>tournament</wired-item>
                </wired-listbox>
            </div>  
           
            {
                !isTournament && (<>
                    <br/>
                    <div>
                        <wired-listbox horizontal selected={target} ref={targetRef}>
                            <wired-item value="ai">AI</wired-item>
                            <wired-item value="device">on one device</wired-item>
                            <wired-item value="online">due internet</wired-item>
                        </wired-listbox>
                    </div>
                    {
                        (target === 'ai') &&
                        <div>
                            <h5>level: </h5>
                            <wired-listbox horizontal selected={levelAI} ref={aiRef}>
                                <wired-item value="low">easy</wired-item>
                                <wired-item value="mid">medium</wired-item>
                                <wired-item value="high">hard</wired-item>
                            </wired-listbox>
                        </div>
                    }
                    {
                        (target === 'online') &&

                        <div>
                            <h5>access: </h5>
                            <wired-button disabled>share by link</wired-button>
                            &nbsp;
                            <label>in app</label>
                            &nbsp;
                            <label>share</label>
                            <br />
                            <br />
                            <label>ServerStatus: {serverStatus}</label>
                            <br />
                            <br />
                            {
                                (isOnline) &&
                                <wired-button onClick={create}>Create game!</wired-button>
                            }
                            {
                                (!isOnline) &&
                                <h3> waiting for connection ...</h3>
                            }
                        </div>
                    }

                    <br />
                    {
                        (inDevelopment) &&
                        <h3> under construction ...</h3>
                    }
                    {
                        (!isOnlineTarget) &&
                        <wired-button onClick={play}>Lets go!</wired-button>
                    }
                    
                </>)
            }
            {
                isTournament && (
                    <div>
                        <h5>access: </h5>
                        <wired-button disabled>share by link</wired-button>
                        &nbsp;
                        <label>in app</label>
                        &nbsp;
                        <label>share</label>
                        <br />
                        <br />
                        <label>max-user-count: x</label>
                        <br />
                        <label>ServerStatus: {serverStatus}</label>
                        <br />
                        <br />
                        {
                            (isOnline) &&
                            <wired-button onClick={createTournamentAction}>Create tournament!</wired-button>
                        }
                        {
                            (!isOnline) &&
                            <h3> waiting for connection ...</h3>
                        }
                    </div>
                )
            }

        </div >
    );
}

const mapDispatchToProps = dispatch => ({
    startGameAction: (users) => dispatch(startGameAction(users)),
    pushLocalPlay: () => dispatch(push('/game/local/play')),
    pushJoin: (id) => dispatch(push(`/game/${id}/join`)),
    pushJoinTournament: (id) => dispatch(push(`/tournament/${id}/join`)),
    startChannelAction: () => dispatch(startChannel()),
    stopChannelAction: () => dispatch(stopChannel())
});

export default connect(state => ({
    serverStatus: state.socket.serverStatus,
    channelStatus: state.socket.channelStatus,
    username: state.app.username,
}), mapDispatchToProps)(SelectPage);
