
const prefix = "WF9";

export const setValue = (key, value) => {
  try {
    localStorage.setItem(`${prefix}.${key}`, JSON.stringify(value));
  } catch (exc) {
    console.error(exc);
  }
};

export const getValue = (key) => {
  try {
    const data = localStorage.getItem(`${prefix}.${key}`);
    return JSON.parse(data);
  } catch (exc) {
    console.error(exc);
    return null;
  }
};