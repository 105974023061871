import React, { useEffect, useState } from 'react';
import { useSpring } from '@react-spring/web'
import { Rectangle, Line, Circle } from 'react-rough';
import * as configLayout from '../../config/layout';
import { getPointFromIndex } from '@wf9/shared/game/maths';
import { cells } from '@wf9/shared/game/config';
import AnimatedRoughG from '../animation/AnimatedRoughG';


const { xCount, yCount } = cells;
const margin = 3;
const { width, height } = configLayout.size;
const wDiff = width / xCount;
const hDiff = height / yCount;

const Cell = (props) => {
  const { player, isAlive, available, index } = props;
  const { x, y } = getPointFromIndex(index);

  const fill = isAlive ? 'transparent' : configLayout.players[player].color;
  const fillAvailable = available ? configLayout.players[available].colorAvailable : 'white';
  const stroke = isAlive ? configLayout.players[player].color : '#fff';

  const isNone = player === 'none';
  const isCircle = player === 'red' ^ isAlive;

  const [init, setInit] = useState(false);

  const realHeight = hDiff - 2 * margin;
  const realWidth = wDiff - 2 * margin;
  const realX = x * wDiff + margin;
  const realY = y * hDiff + margin;

  const transformOriginX = realX + realWidth / 2;
  const transformOriginY = realY + realHeight / 2;

  const translateX = (width / 2 - transformOriginX) / 3;
  const translateY = (width / 2 - transformOriginX) / 3;

  const staticStyle = {
    zIndex: 1,
    pointerEvents: 'none',
    transformOrigin: `${transformOriginX}px ${transformOriginY}px`
  };

  const [boxSpring, boxApi] = useSpring(() => ({
    from: {
      transform: "translate3d(100px, 0, 0) scale(1)",
      opacity: 0,
    },
    to: {
      transform: "translate3d(0, 0, 0) scale(1)",
      opacity: 1,
    },
    delay: index * 50,
    config: {
      mass: 2,
      tension: 250,
    },
  }), []);

  useEffect(()=>{
    if(!init){
      return;
    }
    boxApi.start({
      transform: `translate3d(${translateX}px, ${translateY}px, 5px) scale(3)`,
      opacity: 0,
      immediate: true,
      zIndex: 3
    });

    setTimeout(()=>{
      boxApi.start({
        transform: "translate3d(0, 0, 1px) scale(1)",
        opacity: 1,
        zIndex: 2
      });
    }, 100);
  },[isAlive, player]);

  useEffect(()=>{
    setInit(true);
  },[]);

  return (
    <AnimatedRoughG
      style={{
        ...staticStyle,
        ...boxSpring
      }}
    >
      <>
        <rect
          height={realHeight}
          width={realWidth}
          x={realX}
          y={realY}
          fill="white"
        />
        <Rectangle
          height={realHeight}
          width={realWidth}
          x={realX}
          y={realY}
          fill={fill}
          hachureGap={5}
          fillWeight={3}
        />

        {!!available ? (
          <Rectangle
            height={hDiff - 2 * margin}
            width={wDiff - 2 * margin}
            x={x * wDiff + margin}
            y={y * hDiff + margin}
            fill={fillAvailable}
            stroke={null}
            hachureGap={5}
            fillWeight={3}
          />
        ) : null}

        {!isNone &&
          (!isCircle ? (
            <g>
              <Line
                x1={x * wDiff + margin * 4}
                y1={y * hDiff + margin * 4}
                x2={(x + 1) * wDiff - margin * 4}
                y2={(y + 1) * hDiff - margin * 4}
                strokeWidth={4}
                stroke={stroke}
              />
              <Line
                x1={(x + 1) * wDiff - margin * 4}
                y1={y * hDiff + margin * 4}
                x2={x * wDiff + margin * 4}
                y2={(y + 1) * hDiff - margin * 4}
                strokeWidth={4}
                stroke={stroke}
              />
            </g>
          ) : (
            <g>
              <Circle
                x={(x + 0.5) * wDiff}
                y={(y + 0.5) * hDiff}
                diameter={wDiff - 6 * margin}
                strokeWidth={4}
                stroke={stroke}
              />
            </g>
          ))}
      </>
    </AnimatedRoughG>
  );
}

export default Cell;
