// @flow
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import socket from '../modules/online/online.reducer';
import board from '../modules/game/game.reducer';
import tournament from '../modules/tournament/tournament.reducer';
import app from '../modules/app/app.reducer';

const rootReduce = (history) => combineReducers({
  app,
  board,
  tournament,
  socket,
  router: connectRouter(history),
})

export default rootReduce;