import { cells } from './config.js';
const { xCount, yCount } = cells;

export const getIndexByPoint = (x, y) => {
    return y * xCount + x;
};

export const getPointFromIndex = (index) => {
    return {
        y: Math.floor(index / xCount),
        x: index % xCount
    };
}

export const getNeighbors = (index) => {
    const results = [];
    const { x, y } = getPointFromIndex(index);

    if (x > 0) {
        results.push(index - 1);
        if (y > 0) {
            results.push(index - 1 - xCount);
        }
        if (y < yCount - 1) {
            results.push(index - 1 + xCount);
        }
    }

    if (x < xCount - 1) {
        results.push(index + 1);
        if (y > 0) {
            results.push(index + 1 - xCount);
        }
        if (y < yCount - 1) {
            results.push(index + 1 + xCount);
        }
    }

    if (y > 0) {
        results.push(index - xCount);
    }
    if (y < yCount - 1) {
        results.push(index + xCount);
    }

    return results;
};


export const isNew = cell => !cell.isAlive && cell.player === 'none';
export const isEnemy = (cell, player) => cell.isAlive && cell.player !== player;
export const isPlayer = (cell, player) => cell.player === player && cell.isAlive;
export const isPlayerOccupated = (cell, player) => cell.player === player && !cell.isAlive;

export const searchAvailableIndexes = (cells, player) => {
    const results = [];
    const activeIndexes = cells
        .map((cell, index) => ({ cell, index }))
        .filter(({ cell }) => isPlayer(cell, player))
        .map(({ index }) => index);

    for (let i = 0; i < activeIndexes.length; i++) {
        const index = activeIndexes[i];
        const neighbors = getNeighbors(index);
        for (const neiborIndex of neighbors) {
            const neibor = cells[neiborIndex];
            if (isNew(neibor) || isEnemy(neibor, player)) {
                !results.includes(neiborIndex) && results.push(neiborIndex);
            } else if (isPlayerOccupated(neibor, player)) {
                !activeIndexes.includes(neiborIndex) && activeIndexes.push(neiborIndex);
            }
        }
    }

    return results;
};

export const addAvaibleSelector = (cells, player) => {
    const activeIndexes = searchAvailableIndexes(cells, player);
    return cells.map((cell, index) => {
        // console.log(cells, index);
        const isAvailable = activeIndexes.includes(index);
        return isAvailable ? {
            ...cell,
            available: player
        } : cell
    });
}
