import { setValue, getValue } from './storage.service';

const appStoreKey = "APP_STATE";


export const reHydrateStore = () => {
  const value = getValue(appStoreKey);
  if(!value){
    return undefined;
  }
  const { href, state} = value;
  return global.location.href === href ? state: undefined;
};


export const saveStoreState = (state) => {
  const stateCopy = {
    ...state,
    socket: undefined
  }
  const href = global.location.href;
  const value = {
    state: stateCopy, href
  };
  
  setValue(appStoreKey, value);
};

window.onbeforeunload = function(){
  const state = global.store.getState();
  saveStoreState(state);
};
