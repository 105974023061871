import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'wired-elements';
import * as serviceWorker from './serviceWorker';
import { history, configureStore } from './redux/store';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import HomePage from './containers/pages/HomePage';
import SelectPage from './containers/pages/SelectPage';
import PlayFieldPage from './containers/pages/PlayFieldPage';
import JoinPage from './containers/pages/JoinPage';
import JoinTournamentPage from './containers/pages/JoinTournamentPage';
import PlayTournamentPage from './containers/pages/PlayTournamentPage';
import PWAInstall from './components/utility/PWAInstall';
// import DevTools from './containers/DevTools';

serviceWorker.register();

const store = configureStore();

global.store = store;

ReactDOM.render(
  <Provider store={store}>
    {/* <DevTools /> */}
    <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */}
      <> { /* your usual react-router v4/v5 routing */}
        <Switch>
          <Route exact path="/" render={() => (<HomePage />)} />
          <Route exact path="/select" render={() => (<SelectPage />)} />
          
          <Route exact path="/game/local/play" render={() => (<PlayFieldPage />)} />
          <Route exact path="/game/:gameId/join" render={() => (<JoinPage />)} />
          <Route exact path="/game/:gameId/play" render={() => (<PlayFieldPage />)} />

          <Route exact path="/tournament/:tournamentId/join" render={() => (<JoinTournamentPage />)} />
          <Route exact path="/tournament/:tournamentId/play" render={() => (<PlayTournamentPage />)} />
          
          <Route render={() => (<h1 style={{textAlign: 'center', marginTop: '40%' }}>404</h1>)} />
        </Switch>
      </>
    </ConnectedRouter>
    <PWAInstall/>
  </Provider>
  , document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA  