import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as configLayout from '../../config/layout';
import { setUsername, genereteUsername } from '../../modules/app/app.actions';

const { width } = configLayout.size;

const mWidth = width * 0.8;

const style = {
  position: 'fixed',
  width: mWidth,
  padding: 10,
  top: '40%',
  left: '50%',
  marginLeft: -mWidth / 2,
  background: '#fff'
}

const wrapperStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  background: 'rgba(0,0,0,0.2)',
  zIndex: 10
}

const UserLink = (props) => {
  const { username, setUsername, genereteUsername } = props;
  const usernameInput = React.createRef();
  const [isOpen, setIsOpen] = useState(false);
  const ok = () => {
    const name = usernameInput.current.value.trim();

    if(name.length){
      setUsername(name, true);
    } else {
      genereteUsername();
    }
    setIsOpen(false);
  };

  const calcel = () => {
    setIsOpen(false);
  };

  const onClick = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  return <>
    <a href="#" onClick={onClick}>{username}{!username.length && '_'}</a>
    {isOpen && (
      <div style={wrapperStyle}>
        <wired-card style={style}>
          <h3>USER SETTINGS</h3>
          username: <wired-input ref={usernameInput} maxlength="16" value={username}/><br /><br />
          <wired-button onClick={ok}>ok</wired-button>
          <wired-button onClick={calcel}>calcel</wired-button>
        </wired-card>
      </div>
    )}
  </>
}

const mapDispatchToProps = dispatch => ({
  setUsername: (username, origin) => dispatch(setUsername(username, origin)),
  genereteUsername: (username, origin) => dispatch(genereteUsername()),
});

export default connect(state => state.app, mapDispatchToProps)(UserLink);
