import { eventChannel } from 'redux-saga';
import { take, call, put, fork, takeLatest } from 'redux-saga/effects';
import { SET_USERNAME } from '../app/app.actions';
import { SERVER_SYNC } from '../game/game.actions';
import { START_TOURNAMENT, WATCH_TOURNAMENT_GAME_STATUS, TRIGGER_TOURNAMENT_GAME_STATUS, triggerTournamentGameStatus } from './online.actions';
import { getSocket } from './online.saga';



// This is how channel is created
const createSocketChannel = socket => eventChannel((emit) => {
  const handler = (data) => {
    emit(data);
  };
  socket.on('tournament_sync', handler);
  return () => {
    socket.off('tournament_sync', handler);
  };
});

function* watchUserActionSaga() {
  while (true) {
    //todo add proper actions
    const action = yield take([
      SET_USERNAME,
      START_TOURNAMENT,
      TRIGGER_TOURNAMENT_GAME_STATUS,
    ]);

    const socket = getSocket();
    if (socket) {
      socket.emit("tournament_action", action);
    }
  }
}

function* watchGameStateSaga() {
  yield takeLatest([WATCH_TOURNAMENT_GAME_STATUS], function* (action) {
      const gameId = action.payload.gameId;
      let gameStatus = undefined;
      while (true) {
        const syncAction = yield take([SERVER_SYNC]);
        const syncSameId = syncAction.payload.gameId;
        const nextGameStatus =
          gameId === syncSameId ? syncAction.payload.status : undefined;
        if (gameStatus !== nextGameStatus) {
          gameStatus = nextGameStatus;
          yield put(triggerTournamentGameStatus(gameId));
        }
      }
    // } finally {
    //   if (yield cancelled())
    //     yield put(triggerTournamentGameStatus(gameId));
    // }
  });
}

export function* tournamentSaga(socket){
  const socketChannel = yield call(createSocketChannel, socket);
  yield fork(watchUserActionSaga);
  yield fork(watchGameStateSaga);
  while (true) {
    const action = yield take(socketChannel);
    //todo add type checks
    console.error('Socket.action', JSON.stringify(action));
    yield put({ type: action.type, payload: action.payload });
  }
}


export const createTournament = async (username) => {
  const socket = getSocket();
  return new Promise((resolve) => {
    socket.emit('tournament_create', { username }, (data) => {
      resolve(data);
    });
  });
}

export const joinTournament = async (id, username) => {
  const socket = getSocket();
  return new Promise((resolve) => {
    socket.emit('tournament_join', { id, username },(data) => {
      resolve(data);
    });
  });
}