import gamerNamer from 'gamer-namer';

export const SET_USERNAME = 'SET_USERNAME';

export const setUsername = (username) => ({
  type: SET_USERNAME, payload: {
    username,
    originalUsername: true
  }
});

export const genereteUsername = () => ({
  type: SET_USERNAME, payload: {
    username: gamerNamer.generateName(),
    originalUsername: false
  }
});