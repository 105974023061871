import React, { useEffect, useMemo, useState } from "react";
import { useSpring, useSpringRef, animated } from '@react-spring/web'


function AnimatedTableCell(props) {
  const [i,j] = props.indexes;
  const size = props.size;
  const deps = props.deps;
  const children = props.children;
  const boxIndex = i * size + j;

  const [init, setInit] = useState(false);

  const [boxSpring, boxApi] = useSpring(()=>({
    from: {
      translateX : 100,
      opacity: 0
    },
    to: {
      translateX : 0,
      opacity: 1
    },
    delay: boxIndex * 250,
    config: {
      mass: 2,
      tension: 220,
    },
  }));


  useEffect(()=>{
    if(!init){
      return;
    }
    boxApi.start({
      translateX : 100,
      opacity: 0,
      immediate: true,
    });

    setTimeout(()=>{
      boxApi.start({
        translateX : 0,
        opacity: 1,
      });
    }, 100);
  },[deps]);

  useEffect(()=>{
    setInit(true);
  },[]);


  return (<animated.div
      style={boxSpring}
    >
      {children}
    </animated.div>);
}


export default AnimatedTableCell;