import React from 'react';
import './HomePage.css';
import { connect } from 'react-redux';
import * as configLayout from '../../config/layout';
import Board from '../../components/game/Board';
import Header from '../../components/game/Header';
import WinMessage from '../../components/game/WinMessage';
import { giveUpAction } from '../../modules/game/game.actions'



function PlayfieldPage(props) {

  return (
    <div className="Home">
      <Header/>
      <Board/>
      <WinMessage/>
    </div >
  );
}

const mapDispatchToProps = dispatch => ({
  // giveUpAction: (player) => dispatch(giveUpAction(player))
});

export default connect(state => state.board, mapDispatchToProps)(PlayfieldPage);
