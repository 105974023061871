import React, { useEffect, useCallback } from 'react';
import { startGameAction } from '../../modules/game/game.actions';
import { startChannel, stopChannel } from '../../modules/online/online.actions';
import { joinGame } from '../../modules/online/online.saga';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import QRBoard from '../../components/qr/QRBoard';


function JoinPage(props) {
    const {
        startChannelAction, pushPlay, startGameAction,
        serverStatus, username
    } = props;

    const params = useParams();
    const gameId = params.gameId;

    const text = window.location.href;

    useEffect(() => {
        startChannelAction();
    }, []);

    const shareAction = useCallback(() => {
        navigator.share({
            title: 'Send link for join to game!',
            text: 'Play WF9',
            url: text,
        });
    }, []);

    useEffect(() => {
        async function join() {
            if (serverStatus === 'on') {
                const data = await joinGame(gameId, username);
                console.log(data);
                // startGameAction(data);
                pushPlay(gameId);
            }
        }
        join();
    },
        [serverStatus],
    );

    return (
        <div className="Home">
            <h3>#join game</h3>
            <wired-button onClick={shareAction}>share !!!</wired-button>
            <br />
            <label>ServerStatus: {serverStatus}</label>
            <br />
            <h3>users:</h3>
            <label>#1 red</label>
            <br />
            <label>#2 blue</label>
            <br />
            <QRBoard text={text}></QRBoard>
        </div >
    );
}

const mapDispatchToProps = dispatch => ({
    startChannelAction: () => dispatch(startChannel()),
    startGameAction: (users) => dispatch(startGameAction(users)),
    pushPlay: (gameId) => dispatch(replace(`/game/${gameId}/play`))
});

const mapToProps = state => ({
    serverStatus: state.socket.serverStatus,
    username: state.app.username
});

export default connect(mapToProps, mapDispatchToProps)(JoinPage);
