import { SERVER_TOURNAMENT_SYNC } from './tournament.actions';
import { tournamentChangeRouteGuard } from '../route/route.helpers';

export default function (state = null, action) {
  // aka CLIENT_TOURNAMENT_CLEAN
  if(state && tournamentChangeRouteGuard(action, state.tournamentId)){
    state = null;
  }

  if (action.type === SERVER_TOURNAMENT_SYNC) {
    return action.payload;
  }

  return state;
};