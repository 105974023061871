import color from 'color';


// window.screen.lockOrientationUniversal = window.screen.lockOrientation || window.screen.mozLockOrientation || window.screen.msLockOrientation;

// if (window.screen.lockOrientationUniversal("landscape-primary")) {
//   // Orientation was locked
// } else {
//   // Orientation lock failed
// }


function isMobileDevice() {
  return global && typeof global.orientation !== "undefined";
};


export const size = isMobileDevice()? {
  width: window.screen.width - 10,
  height: window.screen.height - 135
}: {
  width: 360,
  height: 640
}

export const cells = {
  margin: {
    vertical: 2,
    horisontal: 2
  }
}

export const players = {
  red: {
    color: '#ED553B',
    colorAvailable: color('#ED553B').alpha(0.25).string()
  },
  blue: {
    color: '#20639b',
    colorAvailable: color('#20639b').alpha(0.25).string()
  },
  none: {
    color: 'transparent'
  }
};