import { SET_USERNAME } from './app.actions';

const initialState = {
  username: 'username',
  originalUsername: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERNAME:
      const { username, originalUsername } = action.payload;
      return { ...state, username, originalUsername };
    default:
      return state;
  }
};