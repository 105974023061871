import reducer from '@wf9/shared/game/reducer';
import { SERVER_SYNC } from './game.actions';
import { gameChangeRouteGuard } from '../route/route.helpers';

export default function (state, action) {
  // aka CLIENT_CLEAR
  if(state && gameChangeRouteGuard(action, state.gameId)){
    state = undefined;
  }

  if (action.type === SERVER_SYNC) {
    return action.payload;
  }

  return reducer(state, action);
};