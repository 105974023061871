import React, { useEffect, useState } from 'react';

export function useAddToHomescreenPrompt() {
  const [
    promptable,
    setPromptable,
  ] = React.useState(null);

  const [isInstalled, setIsInstalled] = React.useState(false);

  const promptToInstall = () => {
    if (promptable) {
      return promptable.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      setPromptable(e);
    };

    window.addEventListener("beforeinstallprompt", ready);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  React.useEffect(() => {
    const onInstall = () => {
      setIsInstalled(true);
    };

    window.addEventListener("appinstalled", onInstall);

    return () => {
      window.removeEventListener("appinstalled", onInstall);
    };
  }, []);

  return [promptable, promptToInstall, isInstalled];
}


const style = {
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
  background: '#fff'
}


export default function PWAInstall() {
  const [closed, setClosed] = useState(false)
  const [promptable, promptToInstall, isInstalled] = useAddToHomescreenPrompt();

  return (!closed && promptable && !isInstalled) ? (
    <wired-card style={style}>
      <wired-button onClick={promptToInstall}>INSTALL APP</wired-button>

      <wired-button style={{ float: 'right' }} onClick={() => setClosed(true)}>X</wired-button>
    </wired-card>
  ) : null;
}