import React, { useState, useEffect } from 'react';
import './HomePage.css';
import { xml2js } from 'xml-js';
import ReactRough from 'react-rough';
import { Path } from 'react-rough';
import startArt from 'assert/arts/start_art.svg';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ReactSVG } from 'react-svg';
import UserLink from 'components/app/UserLink';


function HomePage(props) {
  const { pushSelect } = props;
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer => timer + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (

    <div className="Home">
      <h3>#hello &gt; <UserLink/></h3>
      <wired-button key={timer} onClick={pushSelect}>Play !!!</wired-button>
      <ReactSVG className="players-svg-container" src={startArt} />
    </div >
  );
}


const mapDispatchToProps = dispatch => ({
  pushSelect: () => dispatch(push('/select'))
});

export default connect(null, mapDispatchToProps)(HomePage);
