export const HIT = 'HIT'; 
export const SKIP = 'SKIP'; 
export const GIVE_UP = 'GIVE_UP'; 
export const START = 'START'; 

export const hitAction = (player, point) => {
  return {
    type: HIT,
    payload: {
      player,
      point
    }
  }
};

export const skipAction = (player) => {
  return {
    type: SKIP,
    payload: {
      player
    }
  }
};

export const giveUpAction = (player) => {
  return {
    type: GIVE_UP,
    payload: {
      player
    }
  }
};

export const startGameAction = (users) => {
  return {
    type: START,
    payload: {
      users
    }
  }
};
