import React, { useState, useEffect, useRef } from 'react';
import { Rectangle, Line, Circle } from 'react-rough';
import * as configLayout from '../../config/layout';

const margin = 10;
const fill = '#fff';

const { width, height } = configLayout.size;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}


const WinGraph = (props) => {
  const { player } = props;
  const isCircle = player !== 'red';
  const stroke = configLayout.players[player].color;

  const [counter, setCounter] = useState(0);
  const [opacity, setOpacity] = useState(0);

  useInterval(() => {
    setCounter(Math.ceil(Math.random() * (width / 40)));
    setOpacity(1);
  }, 400);

  const strokeWidth = width / 8 + counter;

  return <g style={{ pointerEvents: 'none', transition: 'opacity 1s ease-in-out', opacity }}>

    {(!isCircle) ?
      <g>
        <Line
          x1={0 + margin * 4}
          y1={0 + margin * 4}
          x2={width - margin * 4}
          y2={height - margin * 4}
          strokeWidth={strokeWidth + 10} stroke={fill} />
        <Line
          x1={width - margin * 4}
          y1={0 + margin * 4}
          x2={0 + margin * 4}
          y2={height - margin * 4}
          strokeWidth={strokeWidth + 10} stroke={fill} />
        <Line
          x1={0 + margin * 4}
          y1={0 + margin * 4}
          x2={width - margin * 4}
          y2={height - margin * 4}
          strokeWidth={strokeWidth} stroke={stroke} />
        <Line
          x1={width - margin * 4}
          y1={0 + margin * 4}
          x2={0 + margin * 4}
          y2={height - margin * 4}
          strokeWidth={strokeWidth} stroke={stroke} />
      </g>
      :
      <g>
        <Circle
          x={0.5 * width}
          y={0.5 * height}
          diameter={width - 6 * margin}
          strokeWidth={strokeWidth + 20} stroke={fill} />
        <Circle
          x={0.5 * width}
          y={0.5 * height}
          diameter={width - 6 * margin}
          strokeWidth={strokeWidth} stroke={stroke} />
      </g>
    }
  </g >
}

export default WinGraph;
