
import io from 'socket.io-client';
import { take, call, put, fork, race, cancelled, delay } from 'redux-saga/effects';
import { CHANNEL_ON, CHANNEL_OFF, SERVER_OFF, SERVER_ON, STOP_CHANNEL, START_CHANNEL } from './online.actions';
import * as storage from '../storage/storage.service';
import { gameSaga, createGame, joinGame } from './online.game.saga';
import { tournamentSaga, createTournament, joinTournament } from './online.tournament.saga';

const storageKey = 'ONLINE.SAGA.STARTED';

const socketServerURL = window.location.origin;

// wrapping functions for socket events (connect, disconnect, reconnect)
let socket;
const connect = async () => {

  const responce = await fetch('/api/settings');
  const settings = await responce.json();

  console.log('settings', settings);

  socket = io(socketServerURL, { path: '/api/socket.io' });
  return new Promise((resolve) => {
    socket.on('connect', () => {
      resolve(socket);
    });
  });
};

const disconnect = () => {
  return new Promise((resolve) => {
    socket.on('disconnect', () => {
      resolve(socket);
    });
  });
};

const reconnect = () => {
  return new Promise((resolve) => {
    socket.on('reconnect', () => {
      resolve(socket);
    });
  });
};


export function getSocket(){
  return socket;
}

// connection monitoring sagas
const listenDisconnectSaga = function* () {
  while (true) {
    yield call(disconnect);
    yield put({ type: SERVER_OFF });
  }
};

const listenConnectSaga = function* () {
  while (true) {
    yield call(reconnect);
    yield put({ type: SERVER_ON });
  }
};

// Saga to switch on channel.
const listenServerSaga = function* () {
  try {
    yield put({ type: CHANNEL_ON });
    const { timeout, socket } = yield race({
      socket: call(connect),
      timeout: delay(2000),
    });
    if (timeout) {
      yield put({ type: SERVER_OFF });
    }
    // const socket = yield call(connect);
    yield fork(listenDisconnectSaga);
    yield fork(listenConnectSaga);
    yield put({ type: SERVER_ON });

    yield fork(tournamentSaga, socket);

    yield call(gameSaga, socket);
   
  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      socket && socket.disconnect(true);
      yield put({ type: CHANNEL_OFF });
    }
  }
};

const wasStarted = () => storage.getValue(storageKey);
const setStartMark = (mark) => storage.setValue(storageKey, mark);

// saga listens for start and stop actions
export const startStopChannel = function* () {
  let startedAndReloaded = wasStarted()
  while (true) {
    startedAndReloaded || (yield take(START_CHANNEL));
    setStartMark(true);
    startedAndReloaded = false;
    yield race({
      task: call(listenServerSaga),
      cancel: take(STOP_CHANNEL),
    });
    setStartMark(null);
  }
};

export {
  createGame, joinGame,
  createTournament, joinTournament
};