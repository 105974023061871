import {
  HIT,
  SKIP,
  GIVE_UP,
  START,
  giveUpAction,
  skipAction,
  hitAction,
  startGameAction,
} from "@wf9/shared/game/actions";

export const SERVER_SYNC = "SERVER_SYNC";
export const CLIENT_CLEAR = "CLIENT_CLEAR";
export {
  HIT,
  SKIP,
  GIVE_UP,
  START,
  giveUpAction,
  hitAction,
  startGameAction,
  skipAction,
};
