import { createStore, applyMiddleware, compose } from 'redux'
import { createHashHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { push } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga'

// import thunk from 'redux-thunk'
// import { createLogger } from 'redux-logger'
// import api from '../middleware/api'
import { startStopChannel } from '../modules/online/online.saga';
import botSaga from '../modules/bots/bots.saga';
import { storageMiddlewareFactory } from '../modules/storage/storage.middleware';
import { reHydrateStore } from '../modules/storage/storage.init';
import { genereteUsername, SET_USERNAME } from '../modules/app/app.actions';
import rootReducer from './reducer';
import DevTools from '../containers/DevTools';

export const history = createHashHistory();

const sagaMiddleware = createSagaMiddleware();

const enhancer = compose(
  // Middleware you want to use in development:
  applyMiddleware(
    storageMiddlewareFactory([SET_USERNAME]),
    routerMiddleware(history),
    sagaMiddleware
  ),
  // Required! Enable Redux DevTools with the monitors you chose
  DevTools.instrument()
);

function initApp(store){
  const {app, router} = store.getState();


  if(!app.originalUsername && router.location.pathname === '/'){
    store.dispatch(genereteUsername());
  }
}

export const configureStore = preloadedState => {

  // const bot = new botWorker();
  const store = createStore(rootReducer(history), reHydrateStore()/*preloadedState*/, enhancer);

  initApp(store);
  sagaMiddleware.run(botSaga);
  sagaMiddleware.run(startStopChannel);

  if (module.hot) {
    module.hot.accept('./reducer', () =>
      store.replaceReducer(
        require('./reducer') /*.default if you use Babel 6+ */
      )
    );
  }
  return store;
};