import { eventChannel, END } from 'redux-saga';
import { take, call, put, fork, race, cancelled, delay, select, all } from 'redux-saga/effects';
import { hitAction, skipAction } from '../game/game.actions';
import * as storage from '../storage/storage.service';

const bot = new Worker(new URL('./bots.worker.js', import.meta.url));

const storageKey = 'BOTS.SAGA.QUERY';

const selectUser = (state) => state.board.users[state.board.currentPayer].type;

const createBotWorkerChannel = botWorker => eventChannel((emit) => {
  const handler = (event) => {
    emit(event.data);
  };
  botWorker.addEventListener('message', handler);
  return () => {
    botWorker.removeEventListener('message', handler);
  };
});

function* watchBot() {
  const botChannel = yield call(createBotWorkerChannel, bot);
  const count = 3, timeWait = 1000;
  while (true) {
    const botResponce = storage.getValue(storageKey) || (yield take(botChannel));
    let { hits, currentPayer, time, i = 0 } = botResponce;

    console.log('time', time);
    for (; i < count; i++) {
      storage.setValue( storageKey, { ...botResponce, i });
      const hitIndex = hits[i];
      if (typeof hitIndex === 'number') {
        yield delay(timeWait - (!i ? time : 0));
        yield put(hitAction(currentPayer, hitIndex));
      } else {
        yield delay(timeWait / 3 - (!i ? time : 0));
        yield put(skipAction(currentPayer));
      }
    }
    storage.setValue( storageKey, null);
  }
}


function sendToWorker(state, currentUser) {
  const { cells, currentPayer } = state.board;
  bot.postMessage({ cells, currentPayer, level: currentUser.split('.')[1] });
}

function* watchStore() {
  let previousUser;
  while (true) {
    const action = yield take('HIT');
    const state = yield select();
    const currentUser = selectUser(state);
    if (previousUser !== currentUser) {
      if (currentUser.indexOf('bot') === 0) {
        sendToWorker(state, currentUser);
        // bot.start();
      } else {
        // bot.stop();
      }
    }
    previousUser = currentUser;
  }
}


export default function* botSaga() {
  yield all([
    watchBot(),
    watchStore()
  ])
  // code after all-effect
}