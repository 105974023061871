import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect'
import { push } from 'connected-react-router';
import * as configLayout from '../../config/layout';
import { cells } from '@wf9/shared/game/config';

const { xCount, yCount } = cells;
const { width, height } = configLayout.size;
const wDiff = width / xCount;
const hDiff = height / yCount;

const mWidth = width * 0.8;

const style = {
  position: 'fixed',
  width: mWidth,
  padding: 10,
  top: '40%',
  left: '50%',
  marginLeft: -mWidth/2,
  background: '#fff'
}

const wrapperStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  background: 'rgba(0,0,0,0.2)'
}

const WinMessage = (props) => {
  const { status, goHome } = props;
  const ok = (event) => goHome();

  if (!['win.red', 'win.blue'].includes(status)) {
    return null;
  }

  const player = status.split('.')[1];

  return (
    <div style={wrapperStyle}>
      <wired-card style={style}>
        <h2>{player} win !</h2>
        <wired-button onClick={ok}>ok</wired-button>
      </wired-card>
    </div>
  );
}

const mapStateToProps = createSelector(
  state => state.board.status,
  status => ({ status })
);


const mapDispatchToProps = dispatch => ({
  goHome: () => dispatch(push('/'))
});

export default connect(mapStateToProps, mapDispatchToProps)(WinMessage);
