import { saveStoreState } from './storage.init';

export const storageMiddlewareFactory = (actionTypes, selector) => ({ getState }) => {
  return next => action => {
    const result = next(action);
    if (actionTypes.includes(action.type)) {
      saveStoreState(getState());
    }
    return result;
  };
};
