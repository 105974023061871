import * as config from './config.js';
import { HIT, GIVE_UP, SKIP, START } from './actions.js';
import { searchAvailableIndexes } from './maths.js'
const { xCount, yCount } = config.cells;

//type Player = "none" | "red" | "blue";
//type bot = "bot.low" | "bot.mid" | "bot.high";

function initBuild() {
  const cells = Array.from({ length: xCount * yCount }).map(
    (_, i) => ({ player: 'none', isAlive: false })
  );
  cells[0].player = 'red';
  cells[0].isAlive = true;

  cells[cells.length - 1].player = 'blue';
  cells[cells.length - 1].isAlive = true;

  return {
    cells,
    currentPayer: 'red',
    iteration: 0,
    count: 3,
    status: 'joining', //'joining' | 'playing' | 'win.red'
    users: {
      red: {
        type: 'user', //'user.online','bot.low'
        name: 'USER#1'
      }, 
      blue: {
        type: 'user',
        name: 'USER#2'
      }
    }
  };
}

const init = initBuild();

const opposite = player => player === 'red' ? 'blue' : 'red';

const hit = (cells, point, player) => cells.map((cell, index) => {
  if (index === point) {
    let { isAlive, player: cellPlayer } = cell;
    if (!isAlive && cellPlayer === 'none') {
      return { isAlive: true, player };
    } else if (isAlive && cellPlayer !== player) {
      return { isAlive: false, player };
    } else {
      console.error(`Wrong cell hit point ${point}: ${player} `);
      return cell;
    }
  } else {
    return cell;
  }
});

export default function reducer(state = init, action) {
  if (![HIT, SKIP, GIVE_UP, START].includes(action.type)) {
    //console.log(`Not supported action.type ${action.type}`, action);
    return state;
  }

  if ([HIT, SKIP, GIVE_UP].includes(action.type) && state.status !== 'playing') {
    console.error(`Game already finished/not started`, action);
    return state;
  }

  if ([HIT, SKIP].includes(action.type) && state.currentPayer !== action.payload.player) {
    console.error(`Wrong player sequence ${action.payload.player}`, action);
    return state;
  }

  
  if (action.type === HIT) {
    const { point } = action.payload;
    const availablePoints = searchAvailableIndexes(state.cells, action.payload.player)
    if (!availablePoints.includes(point)) {
      console.error(`Not available hit point ${action.payload.point}: ${availablePoints}`, action);
      return state;
    }
  }

  if (action.type === START) {
    const users = action.payload.users || state.users;
    return {
      ...init,
      status: 'playing',
      users
    };
  }

  //GIVE_UP, SKIP, HIT

  const { player } = action.payload;
  let {
    cells,
    currentPayer,
    iteration,
    count,
    status
  } = state;

  iteration++;
  count--;

  if (count <= 0) {
    currentPayer = opposite(player);
    count = 3;
  }

  if (action.type === GIVE_UP) {
    status = `win.${opposite(player)}`
  }

  if (action.type === SKIP) {
    //loop
  }

  if (action.type === HIT) {
    const { point } = action.payload;
    cells = hit(cells, point, player);

    const players = ['red', 'blue'];
    const loser = players.find(player => !searchAvailableIndexes(cells, player).length);

    if (loser) {
      status = `win.${opposite(loser)}`;
    }
  }

  return {
    ...state,
    cells,
    currentPayer,
    iteration,
    count,
    status
  };
}