export const tournamentChangeRouteGuard = (action, tournamentId) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const location = action.payload.location.pathname;
    const tournamentMatch = location.match(/$\/tournament\/([^/]+)\//);

    if(!tournamentMatch){
      return false;
    }

    const nextTournamentId = tournamentMatch[1];
    return nextTournamentId !== tournamentId;
  }
  return false;
}

export const gameChangeRouteGuard = (action, gameId) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const location = action.payload.location.pathname;
    const gameMatch = location.match(/$\/game\/([^/]+)\//);

    if(!gameMatch){
      return false;
    }

    const nextGameId = gameMatch[1];
    return nextGameId !== gameId;
  }
  return false;
}
