import React, { useContext } from "react";
import { animated } from '@react-spring/web'
import { RoughContext } from 'react-rough';


export default function AnimatedRoughG(props){
  const svgGRef = React.useRef();
  const context = useContext(RoughContext);

  return <RoughContext.Provider
			value={{
        ...context,
        ref: svgGRef
      }}
		>
      <animated.g ref={svgGRef} {...props} />
    </RoughContext.Provider>;
}