import React, { useCallback } from "react";
import { connect } from "react-redux";
import * as configLayout from "../../config/layout";
import { giveUpAction } from "../../modules/game/game.actions";

function trimNames(username = "USERNAME") {
  return username.length > 12 ? username.slice(0, 9) + "..." : username;
}

const opposite = player => player === 'red' ? 'blue' : 'red';

const Header = ({
  currentPayer,
  users,
  count,
  iteration,
  status,
  giveUpAction,
}) => {


  const isActive = users[currentPayer].type === "user";
  const username = users[currentPayer].name;

  const giveUpClick = useCallback(() => {
    giveUpAction(isActive ? currentPayer : opposite(currentPayer));
  }, [currentPayer, isActive]);

  return (
    <h3 style={{}}>
      {isActive ? "> " : "|| "}{" "}
      <span style={{ color: configLayout.players[currentPayer].color }}>
        {trimNames(username)}
      </span>{" "}
      |
      <small style={{ fontSize: "0.8em" }}>
        {" "}
        {count}/3 [{iteration}]
      </small>{" "}
      |{" "}
      {status === "playing" && (
        <wired-button onClick={giveUpClick} style={{ verticalAlign: "middle" }}>
          <span style={{ fontSize: "0.7em", lineHeight: "1.2em" }}>
            give <br />
            up :(
          </span>
        </wired-button>
      )}
    </h3>
  );
};

const mapDispatchToProps = (dispatch) => ({
  giveUpAction: (player) => dispatch(giveUpAction(player)),
});

export default connect((state) => state.board, mapDispatchToProps)(Header);
