import React, { useEffect, useCallback } from "react";
import { startChannel, startTournament } from "../../modules/online/online.actions";
import { joinTournament } from "../../modules/online/online.tournament.saga";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { useParams } from "react-router-dom";
import QRBoard from "../../components/qr/QRBoard";
import UserLink from '../../components/app/UserLink';

function JoinTournamentPage(props) {
  const {
    startChannelAction,
    pushPlay,
    startTournament,
    serverStatus,
    username,
    tournament,
  } = props;

  const params = useParams();
  const tournamentId = params.tournamentId;

  const text = window.location.href;

  const startDisable = !tournament || tournament.players <= 3;

  useEffect(() => {
    startChannelAction();
  }, []);

  const shareAction = useCallback(() => {
    navigator.share({
      title: "Send link for join to game!",
      text: "Play WF9",
      url: text,
    });
  }, []);

  useEffect(() => {
    async function join() {
      if (serverStatus === "on") {
        const data = await joinTournament(tournamentId, username);
        console.log("Tournament", data);
      }
    }
    join();
  }, [serverStatus]);


  useEffect(() => {
    if (serverStatus === "on" && tournament && tournament.status === 'playing') {
      pushPlay(tournamentId);
    }
  }, [tournament, serverStatus]);


  return (
    <div className="Home">
      <h3>#join tournament</h3>
      <wired-button onClick={shareAction}>share !!!</wired-button>
      &nbsp;
      {tournament && tournament.isAdmin && (
        <wired-button disable={startDisable} onClick={startTournament}>
          Start !!!
        </wired-button>
      )}
      <br />
      <label>ServerStatus: {serverStatus}</label>
      <br />
      <QRBoard text={text}></QRBoard>
      <h4 style={{ margin: 0 }}>users:</h4>
      <dl>
        {tournament &&
          tournament.players.map((player, i) => (
            <dt>
              #{i + 1}
              {username === player.username && ['> ', <UserLink />]}
              {username !== player.username && [' ', player.username]}
              {tournament.admin === player.username && ' *'} 
            </dt>
          ))}
      </dl>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  startChannelAction: () => dispatch(startChannel()),
  startTournament: () => dispatch(startTournament()),
  pushPlay: (tournamentId) => dispatch(replace(`/tournament/${tournamentId}/play`))
});

const mapToProps = (state) => ({
  serverStatus: state.socket.serverStatus,
  username: state.app.username,
  tournament: state.tournament,
});

export default connect(mapToProps, mapDispatchToProps)(JoinTournamentPage);
