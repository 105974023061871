import React, { useMemo } from "react";
import { connect } from "react-redux";
import {
  watchTournamentGameStatus,
  startChannel,
} from "../../modules/online/online.actions";
import * as configLayout from "../../config/layout";


function RateList(props) {
  const { tournament } = props;
  const players = tournament.players;
  const sortedPlayers = [...players].sort((a, b) => b.score - a.score);

  return (
    <>
      <h3>#Tournament rate</h3>
      <div style={{ display: "inline-block", textAlign: "left" }}>
        <dl>
          {sortedPlayers.map((player, i) => (
            <dt>
              {`${i + 1}. ${player.username}`} : {player.score}
            </dt>
          ))}
        </dl>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
});

const mapToProps = (state) => ({
  serverStatus: state.socket.serverStatus,
  username: state.app.username,
  tournament: state.tournament,
});

export default connect(mapToProps, mapDispatchToProps)(RateList);
