import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import * as configLayout from '../../config/layout';

const { width } = configLayout.size;


const QRBoard = (props) => {
  const { text } = props;

  const [qrurl, setQrurl] = useState(null);

  useEffect(() => {
    QRCode.toDataURL(text, {
        //type: 'txt',
        // type: 'url',
        // errorCorrectionLevel: 'h',
        width: width * 0.8,
        // version: 4,
        // margin: 4
    }, function (err, txt) {
        if(err){
          console.error(err);
        } 
        setQrurl(txt);
    });
}, []);


  if (!qrurl) {
    return null;
  }

  return (
      <img src={qrurl}/>
  );
}


export default React.memo(QRBoard);
