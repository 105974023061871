export const START_CHANNEL = "START_CHANNEL";
export const STOP_CHANNEL = "STOP_CHANNEL";
export const CHANNEL_ON = "CHANNEL_ON";
export const CHANNEL_OFF = "CHANNEL_OFF";
export const SERVER_ON = "SERVER_ON";
export const SERVER_OFF = "SERVER_OFF";

export const START_TOURNAMENT = "START_TOURNAMENT";
export const WATCH_TOURNAMENT_GAME_STATUS = "WATCH_TOURNAMENT_GAME_STATUS";
export const TRIGGER_TOURNAMENT_GAME_STATUS = "TRIGGER_TOURNAMENT_GAME_STATUS";

// export const GAME_CREATE = 'GAME_CREATE';
// export const GAME_JOIN = 'GAME_JOIN';

export const startChannel = () => ({ type: START_CHANNEL });
export const stopChannel = () => ({ type: STOP_CHANNEL });

export const startTournament = () => ({ type: START_TOURNAMENT });
export const watchTournamentGameStatus = (gameId) => ({
  type: WATCH_TOURNAMENT_GAME_STATUS,
  payload: {
    gameId,
  },
});

export const triggerTournamentGameStatus = (gameId) => ({
  type: TRIGGER_TOURNAMENT_GAME_STATUS,
  payload: {
    gameId,
  },
});
